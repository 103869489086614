/* You can add global styles to this file, and also import other style files */
/* Zoetis Clarifide Global Style Sheet */
/* @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css";
@import "../node_modules/primeng/resources/themes/omega/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css"; */
 *{
    outline: none;
}
*:focus{
    outline: none !important;
}
body {
  background: #ececec;
}
.offline-css{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.offline-css .status-text {pointer-events: none;}
.offline-css .user-sidebar li:nth-of-type(2), .offline-css .addscenario {pointer-events: none;}
.offline-css #get-rep-account tr td button.btn.btn-sm-rounded.btn-sky{pointer-events: none;}
.offline-css .easy-sidebar .nav.navbar-nav li:nth-of-type(3) a{pointer-events: none;}
.offline-css .offline{pointer-events: none;}


/* GiGya style start */
#login-gigya-widget-container .gigya-screen.landscape {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

#gigya-login-screen
  a.gigya-composite-control.gigya-composite-control-link.change-pass {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.gigya-screen .change-label {
  margin-top: 0 !important;
}
#login-gigya-widget-container .login-label input,
#gigya-reset-password-form .register-label input {
  background: transparent !important;
  border: 1px solid #000 !important;
  color: #3e3737 !important;
}
.gigya-composite-control.gigya-composite-control-submit.email-save
  .gigya-input-submit {
  margin-right: 10px !important;
  margin-top: -2px !important;
  padding: 7px 20px !important;
  text-transform: uppercase;
  border-radius: 25px !important;
  height: 35px !important;
  border: 1px solid transparent !important;
  float: left !important;
  display: block !important;
  font-size: 16px !important;
  outline: none !important;
  width: 172px !important;
}
.login-label input,
.login-label select {
  font-size: 16px !important;
}
#gigya-register-form
  .gigya-composite-control.gigya-composite-control-header.gigya-header {
  text-align: center !important;
  width: 100% !important;
  font-size: 32px !important;
}
#gigya-register-form .register-label .gigya-label {
  text-align: left !important;
}
#gigya-register-form .register-label select,
#gigya-register-form .register-label input {
  width: 88% !important;
  margin-bottom: 6px !important;
  background: transparent !important;
    border: 1px solid #666 !important;
    border-radius: 23px !important;
    color: #666 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 35px !important;
    padding: 7px 10px !important;
}
#gigya-register-form .gigya-composite-control {
  width: 33% !important;
  height: auto;
  position: relative;
}
#gigya-register-form
  .gigya-composite-control.gigya-composite-control-checkbox.species-check {
    height: auto;
    padding-left: 0 !important;
    width: 68px !important;
}
#gigya-register-form .register-label .gigya-label {
  width: 100% !important;
}
#gigya-register-form
  .gigya-composite-control.gigya-composite-control-header.species-header {
  width: 100% !important;
  height: auto;
  margin-top: 10px;
}

#register-gigya-widget-container_content .gigya-screen.landscape {
  padding-top: 7px !important;
}
#register-gigya-widget-container_content .agree-terms {
  margin-left: 0 !important;
  width: 100% !important;
}
#register-gigya-widget-container_content .gigya-spacer.account-error {
  display: none !important;
}
.gigya-screen .gigya-error-msg-active.gigya-form-error-msg,
.gigya-screen .gigya-error-msg.gigya-error-msg-active {
  position: absolute !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-top: -4px;
}

#register-gigya-widget-container_content  .gigya-multi-choice-item {
  text-align: center;
  margin-left: 0;
  width: auto;
  float: left;
  margin-right: 20px;
}
#register-gigya-widget-container_content  .gigya-composite-control-multi-choice.customer{    width: 100% !important;}
#register-gigya-widget-container_content  .gigya-composite-control-multi-choice.customer > .gigya-label{ display: none !important;}

#register-gigya-widget-container_content .gigya-composite-control-multi-choice.searchby{width: 100% !important;}
#gigya-signup-screen .searchby>label span {
  margin-left: 0 !important;
  text-align: center;
}
#register-gigya-widget-container_content .gigya-composite-control.gigya-composite-control-label.validats {
  width: 16% !important;
  margin-left: 0 !important;
  line-height: 20px;
  padding-bottom: 10px !important;
  margin-top: 35px !important;
  font-size: 16px !important;
  font-weight: 100 !important;
  border-radius: 35px;
  text-align: center;
  min-width: 130px;
}
.inner-sigin-box1 {
  width: 730px;
  margin: 0 auto;
  background: rgba(255,255,255,0.6);
  border-radius: 10px;
  padding-top: 30px;
  min-height: 465px;
  /* max-height: calc(100vh - 180px); */
  overflow: auto;
}
#register-gigya-widget-container_content .gigya-input-submit{border: none;
  font-size: 15px;
  height: 35px;
  border-radius: 35px;
  min-width: 140px;}

  .gigya-composite-control.gigya-composite-control-submit.email-save .gigya-input-submit{    margin: 0 !important;}
  #gigya-reset-password-screen .email-save {
    float: left;
    width: 100% !important  ;
    display: flex !important;
    justify-content: center !important;
}

/* GiGya style End */
